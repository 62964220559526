
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import classicEditor from '@/global-components/ckeditor/classic-editor';
import OrderItemsTable from "@/components/order/OrderItemsTable.vue";
import PaymentTable from "@/components/order/PaymentTable.vue";
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    OrderItemsTable,
    PaymentTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '訂單',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '訂單清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '訂單清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'CreatedTime', title: '建立時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd HH:mm:ss') },
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },  
        {
          field: 'Name',
          title: '購買人名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        { field: 'SaleAmount', title: '定價總額', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'DiscountAmount', title: '扣除總額', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'Amount', title: '總額', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        {
          field: 'Status',
          title: '狀態',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.OrderStatus).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        {
          title: '繳費資訊',
          width: '150',
          slots: { default: "payment" }, align: 'center'
        },
      ],
      promises: {
        query: model ? (params) => {
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('order/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('order/query') : undefined,
        save: model ? (params) => model.dispatch('order/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, height: '800' },
      treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Name',
          title: '購買人',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        {
          field: 'MemberId',
          title: '會員名稱',
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: 'Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'Phone',
          title: '聯絡電話',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'Address',
          title: '郵寄地址',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'InvoiceType',
          title: '發票資訊',
          span: 12,
          itemRender: { name: "$select", options: [{ label: "二聯式", value: "0" }, { label: "三聯式", value: "1" }], props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' } }
          //itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'InvoiceNo',
          title: '統一編號',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'InvoiceTitle',
          title: '發票抬頭',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'SaleAmount',
          title: '定價總額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'DiscountAmount',
          title: '扣除總額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'Amount',
          title: '總額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'OperatorId',
          title: '操作人員',
          span: 12,
          slots: { default: "column-user-id" }
        },
        {
          field: 'Description', title: '處理說明', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        },
        {
          field: 'Remark', title: '備註(顯示於前台)', span: 24,
          titleWidth: 150,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        },
        {
          span: 24,
          slots: { default: "column-orderItems-list" }
        },
      ],
      rules: {
      }
    }

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    const orderItemsTable = ref<any>()

    const initData = (row: any, callback: any) => { 
      row.Published = false;
      row.ValidPeriod = 0;
      row.Description = '';
      callback();
    }

    const remove = async(row: any, callback: any) => {
      if(row && row.Id) {
        row.DataMode = 2;
        await model!.dispatch('order/update', row).then((data) => {
          //console.log(data);
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      }
      //callback();
    }

    const paymentGrid = ref<any>()
    const paymentModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(row: any) {
        paymentModal.visible = true;
        paymentModal.selectedRow = row;
        console.log(row)
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      initData,
      userSelectorOptions,
      memberSelectorOptions,
      orderItemsTable,
      paymentGrid,
      paymentModal,
      remove,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("order/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})

<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">訂單管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @edit="onGridEdit"
          @remove="remove"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #payment="{ row }">
            <vxe-button type="button" content="繳費資訊" @click="paymentModal.show(row)"></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-member-id="{ data }">
                <select-box v-bind="memberSelectorOptions" v-model="data.MemberId" :disabled="true" />
              </template>
              <template #column-user-id="{ data }">
                <select-box v-bind="userSelectorOptions" v-model="data.OperatorId" />
              </template>
              <template #column-orderItems-list>
                <OrderItemsTable ref="orderItemsTable" :data="row.OrderItems" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal v-model="paymentModal.visible" width="80%" title="繳費資訊" height="700" show-zoom="true">
      <PaymentTable ref="paymentGrid" :modelValue="paymentModal.visible" @close="() => (paymentModal.visible = false)" :sid="paymentModal.selectedRow.Id" />
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import classicEditor from '@/global-components/ckeditor/classic-editor';
import OrderItemsTable from "@/components/order/OrderItemsTable.vue";
import PaymentTable from "@/components/order/PaymentTable.vue";
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    OrderItemsTable,
    PaymentTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '訂單',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '訂單清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '訂單清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'CreatedTime', title: '建立時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd HH:mm:ss') },
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },  
        {
          field: 'Name',
          title: '購買人名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        { field: 'SaleAmount', title: '定價總額', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'DiscountAmount', title: '扣除總額', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'Amount', title: '總額', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        {
          field: 'Status',
          title: '狀態',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.OrderStatus).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        {
          title: '繳費資訊',
          width: '150',
          slots: { default: "payment" }, align: 'center'
        },
      ],
      promises: {
        query: model ? (params) => {
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('order/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('order/query') : undefined,
        save: model ? (params) => model.dispatch('order/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, height: '800' },
      treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Name',
          title: '購買人',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        {
          field: 'MemberId',
          title: '會員名稱',
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: 'Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'Phone',
          title: '聯絡電話',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'Address',
          title: '郵寄地址',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'InvoiceType',
          title: '發票資訊',
          span: 12,
          itemRender: { name: "$select", options: [{ label: "二聯式", value: "0" }, { label: "三聯式", value: "1" }], props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' } }
          //itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'InvoiceNo',
          title: '統一編號',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'InvoiceTitle',
          title: '發票抬頭',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' }, }
        },
        {
          field: 'SaleAmount',
          title: '定價總額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'DiscountAmount',
          title: '扣除總額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'Amount',
          title: '總額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'OperatorId',
          title: '操作人員',
          span: 12,
          slots: { default: "column-user-id" }
        },
        {
          field: 'Description', title: '處理說明', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        },
        {
          field: 'Remark', title: '備註(顯示於前台)', span: 24,
          titleWidth: 150,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        },
        {
          span: 24,
          slots: { default: "column-orderItems-list" }
        },
      ],
      rules: {
      }
    }

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    const orderItemsTable = ref<any>()

    const initData = (row: any, callback: any) => { 
      row.Published = false;
      row.ValidPeriod = 0;
      row.Description = '';
      callback();
    }

    const remove = async(row: any, callback: any) => {
      if(row && row.Id) {
        row.DataMode = 2;
        await model!.dispatch('order/update', row).then((data) => {
          //console.log(data);
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      }
      //callback();
    }

    const paymentGrid = ref<any>()
    const paymentModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(row: any) {
        paymentModal.visible = true;
        paymentModal.selectedRow = row;
        console.log(row)
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      initData,
      userSelectorOptions,
      memberSelectorOptions,
      orderItemsTable,
      paymentGrid,
      paymentModal,
      remove,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("order/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
</script>
<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}
.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>

/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  props: {
    sid: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;

    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      async save() {
        await model?.dispatch('payment/update', table.formData).then((data) => {
          emit("close");
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: `${error}`,
          });
        })
      },
    })

    const grid = ref<any>({});
    const gridOptions: GridOptions = {
      title: '繳費紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      showFooter: true,
      columns: [
        { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') : '' },
        { field: 'Type', title: '類型', showHeaderOverflow: true, showOverflow: true, sortable: true,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.PaymentActionType).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        { field: 'Success', title: '是否成功', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
        { field: 'Time', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') : '' },
        { field: 'Number', title: '交易訂單編號', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: {
        query: model ? (params) => {
          if(table.formData.Id) {
            if (params.condition) params.condition.and('PaymentId', Operator.Equal, table.formData.Id)
            else params.condition = new Condition('PaymentId', Operator.Equal, table.formData.Id)
            if (params?.sortings?.length === 0) {
              params.sortings.push({ column: 'CreatedTime', order: 1 });
            }
            return model.dispatch('paymentRecord/query', params)
          } else return new Promise((resolve, reject) => resolve({ data:[], totalCount: 0 }));
        } : undefined,
        queryAll: model ? () => model.dispatch('paymentRecord/query') : undefined,
        save: model ? (params) => model.dispatch('paymentRecord/save', params) : undefined
      },
      modalConfig: { width: "50%", height: "550", showFooter: true },
    }


    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { disabled: 'true' } } },
        {
          field: 'Type',
          title: '類型',
          span: 12,
          itemRender: { name: "$select", props: { disabled: 'true' }, options: model ? Object.values(model.enums.PaymentActionType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        // {
        //   field: 'RequestType',
        //   title: '請求型別',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', disabled: true }, attrs: { type: 'text' } }          
        // },
        {
          field: 'ResponseType',
          title: '回應型別',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', disabled: 'true', placeholder: '請輸入文字' } }          
        },
        {
          field: 'Success',
          title: '是否成功',
          span: 12,
          itemRender: { name: '$select', props: { disabled: 'true' }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Time',
          title: '時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        // { field: 'Request', title: '請求資料', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '', disabled: 'true' } } },
        { field: 'Response', title: '回應資料', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 5, maxRows: 8 }, placeholder: '', disabled: 'true' } } },
      ],
      rules:{
        Name: [{ type: 'string', required: true }],
        Date: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      callback();
    }

    const form = ref<any>();
    const list = ref<any[]>([]);

    const onGridEdit = (row: any, callback: any) => {
      callback()
    }

    watch(() => props.modelValue, async(value: any) => {
      table.formData = {};
      if(value) {
        table.formData = await model?.dispatch("payment/findByOrder", props.sid);
        console.log(table.formData)
        if(table.formData.Id) {
          grid.value.refresh();
        }
      }
    }, { immediate: true });

    const close = () => {
      grid.value.isModalPopup = false;
    }
    
    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      list,
      form,
      table,
      onGridEdit,
      model,
      close,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },    
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});

<template>
  <fieldset class="border rounded m-2 p-2">
    <div v-if="!readonly" class="flex items-center mx-1 mb-2">
      訂單內容
      <!-- <button type="button" class="ml-auto" @click="table.insert">
        <FontAwesome icon="plus" class="w-5 h-5 text-yellow-500" />
      </button> -->
    </div>
    <vxe-table ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column title="名稱" sortable>
      <template #default="{ row }">
        <span v-if="row.RightsId">{{ row.Rights.Name }}</span>
        <span v-else-if="row.BookId">{{ row.Book.Name }}</span>
      </template>
      </vxe-column>
      <vxe-column field="SalePrice" title="商品定價" sortable></vxe-column>
      <vxe-column field="Quantity" title="數量" sortable></vxe-column>
      <vxe-column field="DiscountAmount" title="扣除金額" sortable></vxe-column>
      <vxe-column field="Amount" title="金額" sortable></vxe-column>
      <vxe-column v-if="!readonly" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.read(row)">查看</vxe-button>
            <!-- <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button> -->
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-modal v-model="table.isEditing" :title="table.selectedRow ? '編輯訂單項目' : '新增訂單項目'" width="70%" min-width="600"
      height="400" :loading="table.isLoading" resize destroy-on-close showFooter show-zoom="true">
      <template #default>
        <vxe-form ref="form" :data="table.formData" titleAlign="right" @submit="table.save">
          <vxe-form-item title="會員權益" field="RightsId" :item-render="{}" span="12">
            <template #default="{ data }">
              <select-box v-bind="rightsSelectorOptions" v-model="data.RightsId" :disabled="data.Id" />
            </template>
          </vxe-form-item>
          <vxe-form-item title="書籍" field="BookId" :item-render="{}" span="12">
            <template #default="{ data }">
              <select-box v-bind="bookSelectorOptions" v-model="data.BookId" :disabled="data.Id" />
            </template>
          </vxe-form-item>
          <vxe-form-item title="折扣資料" field="CouponId" :item-render="{}" span="12">
            <template #default="{ data }">
              <select-box v-bind="couponSelectorOptions" v-model="data.CouponId" :disabled="data.Id" />
            </template>
          </vxe-form-item>
          <vxe-form-item title="折扣代碼" field="Code" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Code" placeholder="請輸入文字" type="text" :disabled="true" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="會員權益有效年限" field="ValidPeriod" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.ValidPeriod" placeholder="請輸入數字" :disabled="true" type="number" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="商品定價" field="SalePrice" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.SalePrice" placeholder="請輸入數字" :disabled="true" type="number" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="數量" field="Quantity" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Quantity" placeholder="請輸入數字" :disabled="true" type="number" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="扣除金額" field="DiscountAmount" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.DiscountAmount" placeholder="請輸入數字" :disabled="true" type="number" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="金額" field="Amount" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Amount" placeholder="請輸入數字" :disabled="true" type="number" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="備註" field="Remark" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Remark" placeholder="請輸入文字" :disabled="true" clearable></vxe-input>
             </template>
          </vxe-form-item>
       </vxe-form>
      </template>
      <!-- <template #footer>
        <vxe-button type="button" status="primary" content="確認"
          @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
        </vxe-button>
      </template> -->
    </vxe-modal>
  </fieldset>
</template>
<script lang="ts">
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Author {
  OutSource: boolean;
  Name?: string;
  AuthorId?: string;
  Author?: { Id: string, Name: string };
  Source?: string;
  Ordinal: number;
}

export default defineComponent({
  name: "OrderItemsTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<Author[]>,
      default: () => []
    },
    readonly: Boolean
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: { } as any,
      selectedRow: null as any | null,
      insert() {
        table.formData = { OutSource: false, Ordinal: 0 };
        table.selectedRow = null;
        table.isEditing = true;
      },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      read(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
      },
      async save() {
        table.isLoading = true;
        if (table.formData.AuthorId) {
          const temp = await model?.dispatch("user/find", table.formData.AuthorId)
          table.formData.Author = { Id: temp.Id, Name: temp.Name };
        }
        if (table.selectedRow) {
          Object.assign(table.selectedRow, table.formData)
        } else {
          await grid.value.insert(table.formData);
        }
        grid.value.sort("Ordinal", 'asc');
        table.isEditing = false;
        table.isLoading = false;
      }
    })

    watch(() => props.data, async(value) => {
      const authorIds = value.filter(e => e.AuthorId).map(e => e.AuthorId);
      const authors: any['Author'][] = await model?.dispatch("user/query", { condition: new Condition("Id", Operator.In, authorIds) });
      table.data = value.map(e => {
        if (e.AuthorId) {
          e.Author = authors.find(a => a && a.Id === e.AuthorId)
          e.OutSource = false;
        } else {
          e.OutSource = true;
        }
        return e;
      }).sort((a, b) => a.Ordinal - b.Ordinal);
    }, { immediate: true });

    const form = ref<any>();

    const rightsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("rights/find", value), // eslint-disable-line
        query: params => model!.dispatch("rights/query", params) // eslint-disable-line
      }
    };

    const bookSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("book/find", value), // eslint-disable-line
        query: params => model!.dispatch("book/query", params) // eslint-disable-line
      }
    };

    const couponSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("coupon/find", value), // eslint-disable-line
        query: params => model!.dispatch("coupon/query", params) // eslint-disable-line
      }
    };
    return {
      table,
      grid,
      gridOptions,
      form,
      rightsSelectorOptions,
      bookSelectorOptions,
      couponSelectorOptions,
    }
  },
  methods: {
    getData() {
      // 避免 Author 帶過多關聯使的API維護Mapping
      return this.grid.getTableData().fullData.map((e: Author) => {
        delete e.Author;
        return e;
      });
    }
  }
})
</script>
